@import '../../styles/all';

$border-color: $color-stroke;
$box-shadow-color: $color-inputs;

.c-AccordionCard {
  &__container {
    background-color: white;
    border-bottom: solid;
    border-color: $color-primary;
    border-width: 3px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.25);
    padding: 0 16px;
  }

  &__header {
    align-items: center;
    display: flex;
    height: 60px;
    justify-content: space-between;
  }

  &__label {
    display: inline-flex;
    flex: 1;

    .c-Text {
      color: $color-primary;
    }
  }

  &--defaultRing {
    background-color: $white;
    border: solid 2px $white-gray;
    border-radius: 50%;
    height: 15px;
    margin-right: 14px;
    margin-top: 2px;
    width: 15px;
  }

  &__content {
    border-top: $border-size solid $divider-color;
  }

  &--noRing {
    background-color: $white;
  }

  &--warningRing {
    background-color: $white;
    border: solid 2px $color-hazard;
    border-radius: 50%;
    height: 15px;
    margin-right: 14px;
    margin-top: 2px;
    width: 15px;
  }

  &--successIcon {
    height: 19px;
    margin-right: 14px;
    margin-top: 1px;
    width: 19px;

    &:after {
      content: url('data:image/svg+xml;utf8,<svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="check-circle" class="svg-inline--fa fa-check-circle fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="rgb(0, 196, 104)" d="M256 8C119.033 8 8 119.033 8 256s111.033 248 248 248 248-111.033 248-248S392.967 8 256 8zm0 48c110.532 0 200 89.451 200 200 0 110.532-89.451 200-200 200-110.532 0-200-89.451-200-200 0-110.532 89.451-200 200-200m140.204 130.267l-22.536-22.718c-4.667-4.705-12.265-4.736-16.97-.068L215.346 303.697l-59.792-60.277c-4.667-4.705-12.265-4.736-16.97-.069l-22.719 22.536c-4.705 4.667-4.736 12.265-.068 16.971l90.781 91.516c4.667 4.705 12.265 4.736 16.97.068l172.589-171.204c4.704-4.668 4.734-12.266.067-16.971z"></path></svg>');
    }
  }

  &__status {
    padding: 16px 0;
    width: 50px;
  }

  &__button-container {
    padding: 0;
  }

  &__button {
    background-color: white;
    border: none;
    height: 28px;
    width: 28px;
  }

  &--buttonIcon {
    color: $color-primary;
  }

  &--hidden {
    display: none;
  }
}
