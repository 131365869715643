@import '../../../styles/main';

.c-GoCardlessDirectDebit {
  &__dividerContainer {
    display: none;

    @include mobile-only {
      display: inline;
      margin: 10px 5px;
    }
  }

  &__directDebitHeading {
    color: $tertiary-text-color;
    font-size: $primary-font-size;
    font-weight: $ft-w-500;
    margin-bottom: 11px;
    text-align: left;
  }

  &__contentContainer {
    @include tablet-and-above {
      margin: 0 auto;
      width: 400px;
    }
  }

  &__legalNotice {
    @include mobile-only {
      font-size: 12px;
      margin-top: 30px;
    }
  }

  &__quoteReference {
    color: $color-primary;
  }
}
