@import "../../styles/main";

.c-ProductLandingPage {
  @include standardPageLayout;

  @include tablet-and-above {
    margin: auto;
    max-width: 1300px;
    padding: 50px 0 35px;
    width: 600px;
  }

  &__deviceMessageContainer {
    color: $customDark;
    font-size: 14px;
    padding-top: 15px;
  }

  &__titleContainer {
    @include tablet-and-above {
      margin: auto;
      max-width: 1300px;
      padding: 41px 0 60px;
      width: 100%;
    }
  }

  &__titleText {
    display: none;

    @include tablet-and-above {
      color: $color-primary;
      display: block;
      font-size: $h1-size;
      font-weight: $ft-w-light;
      text-align: center;
    }
  }

  &__line {
    display: none;

    @include tablet-and-above {
      background-color: $color-primary;
      display: block;
      font-weight: $ft-w-light;
      height: 1px;
      margin-bottom: 17px;
      margin-top: 7px;
      text-align: left;
      width: 50px;
    }
  }

  &__content {
    @include tablet-and-above {
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      margin: auto;
    }
  }

  &__phoneIconContainer {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 30px;
    width: 100%;
  }

  &__additonalContent {
    display: flex;
    flex-direction: column;
    width: 80%;
  }

  &__iconContainer {
    display: flex;
    justify-content: center;
    margin: auto;
    padding: 15px 0 30px;

    @include tablet-and-above {
      padding: 0;
      width: 50%;
    }
  }

  &__icon {
    height: auto;
    width: 105px;

    @include tablet-and-above {
      height: auto;
      width: $icon-width-desktop;
    }
  }

  &__deviceIcon {
    height: auto;
    width: $device-icon-mobile-width;

    @include tablet-and-above {
      height: auto;
      width: $device-icon-desktop-width;
    }
  }

  &__linkIcon {
    padding-left: 10px;
  }

  &__buttonContainer {
    @include tablet-and-above {
      margin: auto;
      padding: 0;
      width: 100%;
    }
  }

  &__buttonSpacing {
    margin-top: 15px;
  }

  &__phoneIcon {
    order: 0;
    padding: 5px 10px 5px 5px;
    width: 10%;
  }

  &__spacing {
    padding-top: 40px;
  }

  &__button {
    justify-content: center;
    width: 0;
  }

  &__mobileTitleText {
    @include pageSecondaryTitleFont;

    align-items: center;
    color: $tertiary-text-color;
    display: flex;
    flex-direction: column;
    margin: 0 0 43px;

    @include tablet-and-above {
      display: none;
    }
  }

  a {
    width: 100%;
  }

  &__covidMessageContainer {
    margin-top: 15px;
  }

  &__covidMessage {
    color: $tertiary-text-color;
    font-size: 14px;
    font-weight: $ft-w-500;
    margin: 30px 0;
    text-align: left;
  }

  &__swipeSection {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 98%;

    div {
      width: 100%;
    }
  }
}
