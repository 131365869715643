@import '../../styles/main';

.c-GenericErrorPage {
  @include standardPageLayout;
  @include primaryTextFont;

  color: $tertiary-text-color;
  text-align: center;

  &__textContainer {
    padding-bottom: 10%;
    padding-top: 10%;
    text-align: center;

    @include tablet-and-above {
      padding: 5% 0;
    }
  }

  &__textContentContainer {
    align-items: center;
    display: flex;
    flex-direction: column;
  }

  &__referenceTextContainer {
    margin-bottom: $medium-margin;
    text-align: center;
  }

  &__primaryMessage {
    margin-bottom: $medium-margin;
  }

  &__typicalReasons {
    margin-bottom: $medium-margin;

    @include tablet-and-above {
      margin: 0 auto;
      text-align: center;
      width: 60%;
    }
  }

  &__bulletPoints {
    margin-bottom: 0;
    padding-top: 2%;
    text-align: left;
  }

  &__secondParagraph {
    margin-bottom: $medium-margin;
    text-align: center;
    width: 170px;
  }

  &__buttonContainer {
    justify-content: center;

    @include tablet-and-above {
      margin: auto;
      width: 50%;
    }
  }

  &__warningMessage {
    color: red;
    margin-bottom: $medium-margin;
    text-align: center;

    @include tablet-and-above {
      padding: 0 0 5%;
    }
  }

  .c-Button {
    font-weight: 400;
    padding: 5px 20px;

    @include tablet-and-above {
      padding: 10px 70px;
    }
  }

  &__buttonContainer {
    color: $color-primary;
    margin-top: 30px;
    text-align: center;
    text-decoration: underline;

    a {
      color: $color-primary;
    }
  }

  &--red {
    color: red;
  }

  &__referralNotice {
    text-align: center;
  }
}
