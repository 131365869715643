@import "../../styles/main";

.c-PromoCode {
  &__promoCodeBox {
    background-color: rgba(96, 4, 96, 0.05);
    border: solid 1px $color-primary;
    display: flex;
    flex-direction: row;
    height: 84px;
    justify-content: space-between;
    margin-top: 20px;
    padding: 12px;

    @include mobile-only {
      height: 100px;
    }
  }

  &__labelArea {
    display: flex;
    flex-direction: column;
  }

  &__icon {
    background-color: $white;
    height: 58px;
    width: 101px;
  }

  &__label {
    color: $customDark;
    font-weight: $ft-w-bold;
    size: $primary-size;
  }

  &__subLabel {
    color: $darkishGrey;
    padding-top: 7px;
    size: $secondary-size;
    word-break: break-word;
  }
}
