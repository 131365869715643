@import '../../../styles/main';

.c-YourTravelForm {
  &__container {
    padding: $form-vertical-padding 0;

    @include tablet-and-above {
      margin: 0 auto;
      padding: 50px 0;
      width: $form-container-width;
    }

    @include desktop-and-above {
      width: $form-container-width;
    }

    .c-AccordionText__text {
      font-weight: bold;
    }
  }

  &--collapseGridColumns {
    .c-ButtonGroup {
      grid-template-columns: 1fr;
    }
  }

  &__formPaddingContainer {
    padding: 0 $form-field-padding;
  }

  &__spinnerContainer {
    @include animatedSpinner;
  }
}
